import { db } from '@/firebase/config'

const changeOppStatus = async (oppId, juniorId, decision) => {
  try {
    let docRef = '';
    await db.collection('Opportunities').doc(oppId).collection("registered").where("uid", "==", juniorId).get().then(docs => {
      docs.forEach(doc => {
        docRef = doc.ref;
      })
    })
    await docRef.update({
      status: decision
    })
  } catch (error) {
    alert("changeOppStatus " + error);
  }
};

export default changeOppStatus
