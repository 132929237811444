import { db } from '@/firebase/config'


const sendAcceptNote = async (username, useremail, alumniName, dataSet) => {
  try {
    await db.collection("mail").add({
      to: useremail,
      template: {
        name: 'acceptNote',
        data: {
          username: username,
          alumniName: alumniName,
          opp: dataSet
        }
      }
    })
    // console.log("sendAcceptNote activated.")
  } catch (error) {
    alert("sendAcceptNote: " + error)
  }
};

export default sendAcceptNote
